import ReactPixel from 'react-facebook-pixel';
import { CONFIG_KEYS } from './constants';

const options = {
  autoConfig: true,
  debug: false
};

export const initFacebookPixel = () => {
  ReactPixel.init(CONFIG_KEYS.FB_PIXEL_ID, null, options);
  ReactPixel.pageView();
};

export const trackRegistration = (data) => {
  ReactPixel.track('CompleteRegistration', {
    content_name: 'User Registration',
    status: true
  });
};

export const trackSubscription = (data) => {
  ReactPixel.track('Subscribe', {
    currency: 'USD',
    predicted_ltv: '1000.00'
  });
};

export default ReactPixel; 