import React, { useCallback, useContext, useState, useRef, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { loadStripe } from "@stripe/stripe-js";
import { EmbeddedCheckoutProvider, EmbeddedCheckout } from "@stripe/react-stripe-js";
import SubscriptionService from "../services/subscription-service";
import { AuthContext } from "../contexts/AuthContext";
import { CONFIG_KEYS } from "../utils/constants";
import ReactPixel from "../utils/facebook-pixel";

const stripePromise = loadStripe(CONFIG_KEYS.STRIPE_PUBLIC_KEY);

const StripeCheckout = ({ onCompleted }) => {
  const { currentUser, setCurrentClaims } = useContext(AuthContext);
  const [hasSubscribed, setHasSubscribed] = useState(false);
  const refreshedRef = useRef(false); // useRef to persist the flag
  const timeoutRef = useRef(null); // useRef to keep track of the timeout

  const refreshAuthToken = useCallback(async () => {
    if (currentUser) {
      const retryInterval = 5000; // 5 seconds
      setHasSubscribed(true);
      const maxRetries = 5;
      let attempt = 0;

      const checkClaims = async () => {
        if (attempt < maxRetries && !refreshedRef.current) {
          attempt++;
          try {
            await currentUser.getIdTokenResult(true).then((idToken) => {
              if (idToken.claims.HasPricingAccess) {
                setCurrentClaims(idToken.claims);
                refreshedRef.current = true; // Update the ref
                // Track subscription
                ReactPixel.track("Subscribe", {
                  currency: "USD",
                  value: "29.99",
                });
                if (onCompleted) {
                  onCompleted();
                }
                return;
              }
            });
          } catch (error) {
            console.error("Error refreshing token:", error);
          }

          timeoutRef.current = setTimeout(checkClaims, retryInterval);
        } else {
          console.error("Max retries reached. Claims were not updated.");
        }
      };

      checkClaims();
    }
  }, [currentUser, setCurrentClaims]);

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  const fetchClientSecret = useCallback(() => {
    return SubscriptionService.createStripeCheckoutSession(CONFIG_KEYS.STRIPE_PRICING_ID).then((res) => res.data.result);
  }, []);

  const options = { fetchClientSecret, onComplete: refreshAuthToken };

  return (
    <Box id="checkout" sx={{ mb: 4 }}>
      <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
        <EmbeddedCheckout />
      </EmbeddedCheckoutProvider>
      {hasSubscribed && (
        <Box justifyContent={"center"} mt={2}>
          <Typography variant="body1" color={"text.secondary"} textAlign={"center"}>
            Give us one second while we get things set up..
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default StripeCheckout;
