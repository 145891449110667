import * as React from "react";
import * as ReactDOM from "react-dom/client";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import App from "./App";
import theme from "./theme";
import { BrowserRouter as Router } from "react-router-dom";
import { AuthProvider } from "./contexts/AuthContext";
import { QueryClient, QueryClientProvider } from "react-query";
import { Provider } from "react-redux";
import store from "./store/store";
import { SnackbarProvider } from "notistack";
import { ConfirmProvider } from "material-ui-confirm";
import "./assets/styles/react-medium-image-zoom.css";
import ReactGA from "react-ga4";
import { CONFIG_KEYS } from "./utils/constants";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { clarity } from "react-microsoft-clarity";
import { initFacebookPixel } from './utils/facebook-pixel';

const TRACKING_ID = CONFIG_KEYS.GA_TRACKING_ID;
const clarityId = CONFIG_KEYS.CLARITY_ID;

ReactGA.initialize(TRACKING_ID);
clarity.init(clarityId);
clarity.consent();

// Initialize Facebook Pixel
initFacebookPixel();

const rootElement = document.getElementById("root");
const root = ReactDOM.createRoot(rootElement);

// Create a client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

root.render(
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <Router>
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <ConfirmProvider>
              {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
              <CssBaseline />
              <AuthProvider>
                <SnackbarProvider maxSnack={3}>
                  <App />
                </SnackbarProvider>
              </AuthProvider>
            </ConfirmProvider>
          </LocalizationProvider>
        </ThemeProvider>
      </Router>
    </QueryClientProvider>
  </Provider>
);
